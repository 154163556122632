import React, { useRef, Children } from "react";
// import styled from 'styled-components/macro';
import { Switch, useLocation, matchPath } from "react-router-dom";
import { animated, useTransition, config } from "react-spring";

export const RouteTransition = ({ children }) => {
  const isFirstTransitionRef = useRef(true);
  const location = useLocation();
  const routes = Children.toArray(children);
  const getKey = location => {
    for (const child of routes) {
      const { path, exact, strict } = child.props;
      const match = matchPath(location.pathname, { path, exact, strict });
      if (match) return match.url;
    }
    return null;
  };
  const transitions = useTransition(location, getKey, {
    from: { waitingForExit: 0, enter: 0, in: 0, leave: 0 },
    enter: item => async (next, cancel) => {
      if (isFirstTransitionRef.current === true) {
        isFirstTransitionRef.current = false;
      } else {
        await next({ waitingForExit: 1 });
      }
      await next({ enter: 1, in: 1 });
    },
    leave: { enter: 1, leave: 1, in: 0 },
    config: config.stiff,
  });
  return transitions.map(({ item: location, props, key }, i) => {
    return (
      <animated.div
        key={key}
        style={{
          opacity: props.in,
        }}
      >
        <Switch location={location}>{children}</Switch>
      </animated.div>
    );
  });
};
