import { useReducer, useEffect, useCallback } from 'react'
import { useTranslation } from 'components/LanguageProvider';
import api from 'api/api';

export const useAPI = () => {
    const language = useTranslation("_apiHeader");
    return useCallback(
        path => api(path, language),
        [language]
    );
}

const initialState = { error: null, data: null, loading: true };
const reducer = (state, action) => {
    switch (action.type) {
        case 'start':
            return { loading: true, error: null, data: null };
        case 'data':
            return { loading: false, error: null, data: action.data };
        case 'error':
            return { loading: false, error: action.error, data: null };
        default:
            throw new Error("Unknown action type: " + action.type);
    }
}
export const useAPIData = path => {
    const api = useAPI();
    const [state, dispatch] = useReducer(reducer, initialState);
    useEffect(
        () => {
            let cancelled = false;
            dispatch({ type: 'start' });
            api(path)
                .then(data => {
                    if (cancelled) return;
                    dispatch({ type: 'data', data });
                })
                .catch(error => {
                    if (cancelled) return;
                    dispatch({ type: 'error', error });
                    console.log(`API: error from ${path}`, error);
                })
            return () => cancelled = true
        },
        [api, path, dispatch]
    )
    return state;
}