import React, { useState, useCallback } from "react";
import { AnimatedBox, Image } from 'components/Elements';
import { BookContainer } from './BookContainer';
import { Spread } from './Spread';
import { useSlideTransition } from 'hooks/useSlideTransition';
import { useResponsiveProp } from 'hooks/useBreakpoint';

const supportsClipPath = 'CSS' in window && 'supports' in window.CSS && window.CSS.supports("clip-path", "inset(0 0 0 0)");

const Wipe = ({ t, ...props }) => (
    <AnimatedBox
        position="absolute"
        inset={0}
        zIndex={1}
        overflow="hidden"
        style={{
            clipPath: t.interpolate(t => {
                if (!supportsClipPath) return undefined;
                const left = Math.max(Math.min(t, 1), 0);
                const right = Math.max(Math.min(-t, 1), 0);
                return `inset(0 ${right * 100}% 0 ${left * 100}%)`
            })
        }}
        {...props}
    />
)

export const Book = ({ pages, perPage = [1, 2], ...props }) => {
    const ratio = pages[0].ratio;
    const [page, setPage] = useState(0);
    const currPerPage = useResponsiveProp(perPage);
    const numPages = currPerPage === 1
        ? pages.length
        : Math.ceil((pages.length + 1) / 2);
    const prev = useCallback(
        () => setPage(i => i - 1),
        [setPage]
    );
    const next = useCallback(
        () => setPage(i => i + 1),
        [setPage]
    );
    return (
        <BookContainer prev={page > 0 && prev} next={page < numPages - 1 && next} {...props}>
            <div style={{ paddingBottom: ratio / currPerPage * 100 + "%" }}>
                {useSlideTransition(page, { immediate: !supportsClipPath }).map(({ index: page, t }) => (
                    <Wipe key={page} t={t}>
                        {currPerPage === 1 ? (
                            <Image {...pages[page]} />
                        ) : (
                                <Spread
                                    key={page}
                                    left={pages[page * 2 - 1]}
                                    right={pages[page * 2]}
                                />
                            )}
                    </Wipe>
                ))}
            </div>
        </BookContainer>
    )
}