import React from "react";
import { FlexGrid, Stack, Text } from 'components/Elements';

export const DetailsBlock = ({ details }) => (
    <Stack spacing={[2, 3]}>
        {details.map(({ label, value }, i) => (
            <FlexGrid key={i}>
                <Text.Body width={[1 / 3, 1 / 4]}>
                    {label}
                </Text.Body>
                <Text.Body width={[2 / 3, 3 / 4]}>
                    {value}
                </Text.Body>
            </FlexGrid>
        ))}
    </Stack>
)
