import React from "react";
import { FlexGrid, Box, Text } from 'components/Elements';

export const BlockLayout = ({
    stacked = false,
    title,
    children,
    ...props
}) => {
    return (
        <FlexGrid spacing={[2, stacked ? 2 : 0]} {...props}>
            {(title || !stacked) && (
                <Box
                    width={['100%', stacked ? '100%' : 3 / 12]}
                    display={[title ? 'block' : 'none', 'block']}
                >
                    <Text.Body>
                        {title}
                    </Text.Body>
                </Box>
            )}
            <Box width={['100%', stacked ? '100%' : 9 / 12]}>
                {children}
            </Box>
        </FlexGrid>
    )
}