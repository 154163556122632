import { css } from 'styled-components/macro';
import normalize from 'styled-reset';

export const reset = css`
    ${normalize}
    html {
        -webkit-font-smoothing: antialiased;
    }
    * {
        position: relative;
        box-sizing: border-box;
    }
    a {
        color: inherit;
        text-decoration: inherit;
    }
`