import { baseTheme } from 'styles/theme';
import vec2 from 'utils/vec2';

const spacing = baseTheme.space[2];

const baseLogo = [{
    from: vec2(5.5, 0),
    to: vec2(5.5, 8)
}, {
    from: vec2(0, 7.5),
    to: vec2(4, 7.5)
}, {
    from: vec2(7, 7.5),
    to: vec2(11, 7.5)
}, {
    from: vec2(0, 9.5),
    to: vec2(11, 9.5)
}, {
    from: vec2(0, 11.5),
    to: vec2(11, 11.5)
}];
const baseSize = vec2(11, 12);

export const bars = ({ ww, wh, responsive }) => {
    const scale = Math.min(
        (ww - spacing * 2) / baseSize[0],
        (wh - spacing * 2) / baseSize[1],
        50
    );
    const size = vec2.scale(baseSize, scale);
    const offset = vec2(ww / 2 - size[0] / 2, wh / 2 - size[1] / 2)
    return baseLogo.map(({ from, to }) => ({
        from: vec2.add(vec2.scale(from, scale), offset),
        to: vec2.add(vec2.scale(to, scale), offset),
        width: scale
    }));
}