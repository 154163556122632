import React from 'react';
import { SVG } from 'components/Elements';

export const Sun = props => (
    <SVG width="21px" height="21px" viewBox="0 0 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg" strokeColor="text" fill="text">
        <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <circle id="Oval" fill="#000000" cx="10.5" cy="10.5" r="6.48057174"></circle>
            <path d="M10.5,0.5 L10.5,1.5" id="Line" strokeLinecap="square"></path>
            <path d="M10.5,19.5 L10.5,20.5" id="Line-Copy" strokeLinecap="square"></path>
            <path d="M1,10 L1,11" id="Line" strokeLinecap="square" transform="translate(1.000000, 10.500000) rotate(-90.000000) translate(-1.000000, -10.500000) "></path>
            <path d="M20,10 L20,11" id="Line-Copy" strokeLinecap="square" transform="translate(20.000000, 10.500000) rotate(-90.000000) translate(-20.000000, -10.500000) "></path>
            <path d="M3.78248558,16.7175144 L3.78248558,17.7175144" id="Line" strokeLinecap="square" transform="translate(3.782486, 17.217514) rotate(-135.000000) translate(-3.782486, -17.217514) "></path>
            <path d="M17.2175144,3.28248558 L17.2175144,4.28248558" id="Line-Copy" strokeLinecap="square" transform="translate(17.217514, 3.782486) rotate(-135.000000) translate(-17.217514, -3.782486) "></path>
            <path d="M17.2175144,16.7175144 L17.2175144,17.7175144" id="Line" strokeLinecap="square" transform="translate(17.217514, 17.217514) rotate(-225.000000) translate(-17.217514, -17.217514) "></path>
            <path d="M3.78248558,3.28248558 L3.78248558,4.28248558" id="Line-Copy" strokeLinecap="square" transform="translate(3.782486, 3.782486) rotate(-225.000000) translate(-3.782486, -3.782486) "></path>
        </g>
    </SVG>
)