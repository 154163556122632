import React, { useCallback } from "react";
import { Btn } from 'components/Elements';
import { useLanguage, useSetLanguage, useTranslation } from './LanguageProvider';

export const LanguageToggle = props => {
    const otherLanguage = useLanguage() === 'en' ? 'cn' : 'en';
    const setLanguage = useSetLanguage();
    const toggleLanguage = useCallback(
        () => setLanguage(otherLanguage),
        [otherLanguage, setLanguage]
    )
    const label = useTranslation("_languageName", otherLanguage);
    return <Btn as="button" onClick={toggleLanguage} {...props}>{label}</Btn>
};
