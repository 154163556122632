import React from "react";
import { BlockLayout } from '../BlockLayout';
import { FlexGrid } from 'components/Elements';
import { Thumbnail } from 'components/Thumbnail';

export const ThumbnailsBlock = ({ title, thumbnails }) => (
    <BlockLayout title={title}>
        <FlexGrid spacing={4}>
            {thumbnails.map((props, i) => (
                <Thumbnail
                    width={[1 / 2, 1 / 3]}
                    key={i}
                    {...props}
                />
            ))}
        </FlexGrid>
    </BlockLayout>
);