import React, { useContext } from "react";
import { ThemeContext } from 'styled-components/macro';
import { get } from '@styled-system/core';
import { Stack, Link, title as titleStyle, small as smallStyle } from 'components/Elements';
import { parseISO } from 'utils/date';
import { useLocalDateFormatter } from 'hooks/useFormatDate';
import { NewsTypeLink } from 'components/TypeLink';
import { getResponsiveProp } from 'hooks/useBreakpoint';
import { Headline } from './Headline';
import { TextPlaceholder } from './TextPlaceholder';

const getLineHeight = (theme, style, breakpoint) => {
    const fontSize = getResponsiveProp(style.fontSize, breakpoint);
    const lineHeight = getResponsiveProp(style.lineHeight, breakpoint);
    return get(theme.fontSizes, fontSize, fontSize) * get(theme.lineHeights, lineHeight, lineHeight);
}

export const NewsThumbnail = ({ loading, slug, title = "", newsType, date, fixedHeight = true, ...props }) => {
    const theme = useContext(ThemeContext);
    const formatDate = useLocalDateFormatter();
    const spacing = 2;
    const maxLines = 4;
    const height = fixedHeight
        ? theme.breakpoints.map((breakpoint, i) => {
            const titleHeight = getLineHeight(theme, titleStyle, i) * maxLines;
            const dateHeight = getLineHeight(theme, smallStyle, i);
            const spaceHeight = get(theme.space, spacing, spacing);
            return titleHeight + dateHeight + spaceHeight + 'px';
        })
        : undefined;
    return (
        <Stack spacing={2} {...props}>
            <NewsTypeLink {...newsType} opacity={loading ? 0 : 1} transition="opacity .5s" />
            <Stack as={Link} to={`/whats-on/news/${slug}`} display="block" spacing={spacing} height={height}>
                <Headline maxLines={fixedHeight ? maxLines : undefined} lineHeight="display" overflow="hidden">
                    {title}
                </Headline>
                <TextPlaceholder.Small>
                    {date && formatDate(parseISO(date))}
                </TextPlaceholder.Small>
            </Stack>
        </Stack>
    )
}