import styled from 'styled-components/macro';
import { system } from 'styled-system';
import { Box } from '../Box';

const stackItem = system({
    spacing: {
        property: "marginBottom",
        scale: "space"
    }
});

export const Stack = styled(Box)`
    & > *:not(:last-child) {
        ${stackItem}
    }
`;

Stack.defaultProps = {
    spacing: [2, 4]
}