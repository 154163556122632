import React, { useEffect, Suspense, lazy } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "components/Elements";
import { SHOW_SPECIAL_ANIMATION } from "../../config";

const SpecialAnimation = lazy(() =>
  import("components/SpecialAnimation").then(module => ({
    default: module.SpecialAnimation,
  }))
);

export const Splash = () => {
  const history = useHistory();
  useEffect(() => {
    if (SHOW_SPECIAL_ANIMATION) return;
    const timer = setTimeout(() => history.replace("/whats-on"), 1000);
    return () => clearTimeout(timer);
  }, [history]);
  return (
    <>
      {SHOW_SPECIAL_ANIMATION && (
        <Suspense fallback={null}>
          <SpecialAnimation
            onComplete={() => history.replace("/whats-on")}
            position="fixed"
            top="0"
            left="0"
            width="100%"
            height="100%"
          />
        </Suspense>
      )}
      <Link
        to="/whats-on"
        replace
        position="fixed"
        top="0"
        left="0"
        width="100%"
        height="100%"
      />
    </>
  );
};
