import React, { Fragment } from "react";
import { Box, Text, FlexGrid, Stack, HTML } from 'components/Elements';
import { Loader } from 'components/Loader';
import { Blocks } from 'components/Blocks';
import { PageContainer, Nav } from 'components/Layouts/VisitAndTicketsLayout';
import { useTranslation } from 'components/LanguageProvider';
import QRCodeImage from 'assets/images/QR code.jpeg';

const VisitAndTicketsContent = ({ data }) => (
    <Box py={[2, null, null, 4]}>
        <Stack spacing={[4, 6]}>
            <Text.Title>{useTranslation("visitAndTickets.title")}</Text.Title>
            <FlexGrid spacing={[4, 0]} alignItems={[null, 'stretch']}>
                <Stack width={[null, 5 / 12, 4 / 12]}>
                    <HTML fontWeight={500}>{data.address}</HTML>
                    <FlexGrid gutter={2}>
                        {data.openingTimes.map(({ day, time }, i) => (
                            <Fragment key={i}>
                                <Text.Body width={1 / 2}>{day}</Text.Body>
                                <Text.Body width={1 / 2}>{time}</Text.Body>
                            </Fragment>
                        ))}
                    </FlexGrid>
                    <FlexGrid>
                        {data.details.map(({ label, value }, i) => (
                            <Fragment key={i}>
                                <Text.Body width={1 / 2}>{label}</Text.Body>
                                <Text.Body width={1 / 2}>{value}</Text.Body>
                            </Fragment>
                        ))}
                    </FlexGrid>
                    <Box as="img" src={QRCodeImage} width="100%" maxWidth="200px" />
                </Stack>
                <Box width={['100%', 7 / 12, 8 / 12]} href={data.mapUrl}>
                    <Box paddingBottom={["100%", 0]} height={[null, '100%']}>
                        <Box
                            as="iframe"
                            title="Map"
                            src={data.mapEmbed}
                            position="absolute"
                            width="100%"
                            height="100%"
                        />
                    </Box>
                </Box>
            </FlexGrid>
            <Blocks blocks={data.blocks} width={[null, null, null, 12 / 14]} />
        </Stack>
    </Box>
)

export const VisitAndTickets = () => (
    <>
        <PageContainer>
            <Loader url="/visit-and-tickets" Render={VisitAndTicketsContent} />
        </PageContainer>
        <Nav />
    </>
)