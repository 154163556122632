import React from "react";
import { BlockLayout } from "../BlockLayout";
import { Image, FlexGrid, Box, HTML } from "components/Elements";

export const BioBlock = ({ title, body, image }) => (
  <BlockLayout title={title}>
    <FlexGrid flexDirection={["column-reverse", "row"]} spacing={[2, 0]}>
      <Box width={["100%", 7 / 9]}>{body && <HTML>{body}</HTML>}</Box>
      <Box width={[4 / 12, 2 / 9]}>
        <Image {...image} />
      </Box>
    </FlexGrid>
  </BlockLayout>
);
