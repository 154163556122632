import React from "react";
import { Button, Link } from 'components/Elements';

const NBSP = '\u00A0';

export const ActivityTypeLink = ({ title, slug, ...props }) => (
    <Button as={Link} to={`whats-on/activities?activityType=${slug}`} {...props}>
        {title || NBSP}
    </Button>
)

export const NewsTypeLink = ({ title, slug, ...props }) => (
    <Button as={Link} to={`whats-on/news?newsType=${slug}`} {...props}>
        {title || NBSP}
    </Button>
)

export const ArtworkTypeLink = ({ title, slug, ...props }) => (
    <Button as={Link} to={`psa-collections?artworkType=${slug}`} {...props}>
        {title || NBSP}
    </Button>
)

export const ProductTypeLink = ({ title, slug, ...props }) => (
    <Button as={Link} to={`publication?productType=${slug}`} {...props}>
        {title || NBSP}
    </Button>
)

export const TypeLink = ({ type, ...props }) => {
    switch (type) {
        case "activity": return <ActivityTypeLink {...props} />
        case "news": return <NewsTypeLink {...props} />
        case "artwork": return <ArtworkTypeLink {...props} />
        case "product": return <ProductTypeLink {...props} />
        default: return `Unknown type: ${type}`;
    }
}