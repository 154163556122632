import React, { useRef } from "react";
import { Box, ImageFit } from 'components/Elements';

const pct = x => x * 100 + '%';

export const ImagePlaceholder = ({ loaded, cover = true, position = [0, 0], ratio, image, onLoad }) => {
    const { current: horizontal } = useRef(Math.random() > .5);
    const { current: offset } = useRef(Math.random());
    return (
        <Box
            overflow="hidden"
            style={{
                paddingBottom: ratio * 100 + '%'
            }}
        >
            {(
                <Box
                    position="absolute"
                    backgroundColor="text"
                    width={horizontal ? '100%' : 2}
                    height={horizontal ? 2 : '100%'}
                    transition="opacity .2s"
                    opacity={loaded ? 0 : 1}
                    style={{
                        top: horizontal ? pct(offset) : 0,
                        left: horizontal ? 0 : pct(offset),
                        transform: horizontal
                            ? `translateY(${pct(-offset)})`
                            : `translateX(${pct(-offset)})`
                    }}
                />
            )}
            {image && (
                <ImageFit
                    containerRatio={ratio}
                    position={position}
                    cover={cover}
                    onLoad={onLoad}
                    transition="opacity .2s"
                    opacity={loaded ? 1 : 0}
                    {...image}
                />
            )}
        </Box>
    )
}