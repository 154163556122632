import React, { useState } from "react";
import { Stack, Text, Link } from 'components/Elements';
import { useTranslator } from 'components/LanguageProvider'
import { ImagePlaceholder } from './ImagePlaceholder';
import { TextPlaceholder } from './TextPlaceholder';
import { Headline } from './Headline';

const getTypeName = (type, t) => {
    switch (type) {
        case "exhibition":
            return "whatsOn.exhibitions";
        case "activity":
            return "whatsOn.activities";
        case "news":
            return "whatsOn.news";
        case "product":
            return "aPowerStore.title";
        case "artwork":
            return "psaCollections.title";
        default:
            return "?"
    }
}

const getURL = (type, slug) => {
    switch (type) {
        case "exhibition":
            return `/whats-on/exhibitions/${slug}`;
        case "activity":
            return `/whats-on/activities/${slug}`;
        case "news":
            return `/whats-on/news/${slug}`;
        case "product":
            return `/publication/${slug}`;
        case "artwork":
            return `/psa-collections/${slug}`;
        default:
            return "?"
    }
}

const TextThumbnail = ({ type, title, slug, ...props }) => {
    const t = useTranslator();
    return (
        <Stack spacing={0} as={Link} to={getURL(type, slug)} display="block" {...props}>
            <Text.Small fontWeight={500} lines={1}>{t(getTypeName(type))}</Text.Small>
            <Headline>{title}</Headline>
        </Stack>
    )
}

export const SearchResultThumbnail = ({ loading, type, title, slug, image, ...props }, ref) => {
    const [loaded, onImageLoad] = useState(false);
    const t = useTranslator();
    if (!loading && !image) {
        return <TextThumbnail type={type} title={title} slug={slug} {...props} />
    }
    return (
        <Stack
            spacing={2}
            as={loaded ? Link : undefined}
            to={loaded ? getURL(type, slug) : undefined}
            display="block"
            {...props}
        >
            <ImagePlaceholder
                loaded={loaded}
                image={image}
                ratio={1}
                cover={false}
                onLoad={onImageLoad}
            />
            <TextPlaceholder lines={3} overflow="visible">
                <div style={image ? { marginTop: (1 - Math.min(image.ratio, 1)) * -100 + '%' } : undefined}>
                    <TextPlaceholder.Small fontWeight={500} lines={1}>{!loading && t(getTypeName(type))}</TextPlaceholder.Small>
                    {!image && <Headline>{title}</Headline>}
                    {image && <TextPlaceholder.Small lines={2}>{title}</TextPlaceholder.Small>}
                </div>
            </TextPlaceholder>
        </Stack >
    )
}