import React, { forwardRef } from "react";
import { Link as RouterLink, useLocation, matchPath } from "react-router-dom";
import { Text } from "../Text";

const RouterLinkWrapper = forwardRef(
  ({ to, replace, className, children, style, onClick }, ref) => (
    <RouterLink
      ref={ref}
      to={to}
      replace={replace}
      className={className}
      children={children}
      style={style}
      onClick={onClick}
    />
  )
);
RouterLinkWrapper.displayName = "RouterLinkWrapper";

export const Link = forwardRef((props, ref) => (
  <Text.Body as={RouterLinkWrapper} ref={ref} {...props} />
));

export const NavLink = forwardRef(
  ({ to, exact, strict, activeProps, ...props }, ref) => {
    const location = useLocation();
    const match = matchPath(location.pathname, { path: to, exact, strict });
    return (
      <Link ref={ref} to={to} {...props} {...(match ? activeProps : {})} />
    );
  }
);
NavLink.displayName = "NavLink";
