import React from "react";
import { useAPIData } from 'hooks/useAPI';
import { Box } from 'components/Elements';
import { Error as ErrorComponent } from 'components/Error'
import { Loading as LoadingComponent } from 'components/Loading'

export const Loader = ({ url, Render, Error = ErrorComponent, Loading = LoadingComponent, ...props }) => {
    const { loading, error, data } = useAPIData(url);
    return (
        <Box opacity={loading ? 0 : 1} transition="opacity .2s">
            {error && <Error {...error} {...props} />}
            {!loading && !error && <Render data={data} {...props} />}
        </Box>
    )
};