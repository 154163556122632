import React from 'react';
import { TextPlaceholder } from './TextPlaceholder';
import { map, clamp } from 'utils/math';

export const Headline = ({ children = "", ...props }) => (
    <TextPlaceholder
        fontWeight={300}
        fontSize={Math.floor(map(clamp(children.length, 40, 100), 40, 100, 5, 2))}
        {...props}
    >{children}</TextPlaceholder>
)