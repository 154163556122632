import React from "react";
import { Stack } from 'components/Elements';

import { TextBlock } from './TextBlock';
import { ImageBlock } from './ImageBlock';
import { BioBlock } from './BioBlock';
import { GalleryBlock } from './GalleryBlock';
import { ActivitiesBlock } from './ActivitiesBlock';
import { ExhibitionsBlock } from './ExhibitionsBlock';
import { ProductsBlock } from './ProductsBlock';
import { ArtworksBlock } from './ArtworksBlock';
import { NewsBlock } from './NewsBlock';
import { ProgramsBlock } from './ProgramsBlock';
import { DetailsBlock } from './DetailsBlock';
import { CarouselBlock } from './CarouselBlock';
import { VideoBlock } from './VideoBlock';
import { EmbedBlock } from './EmbedBlock';
import { BookBlock } from './BookBlock';

export const Block = ({ type, ...props }) => {
    switch (type) {
        case 'text': return <TextBlock {...props} />
        case 'image': return <ImageBlock {...props} />
        case 'bio': return <BioBlock {...props} />
        case 'gallery': return <GalleryBlock {...props} />
        case 'activities': return <ActivitiesBlock {...props} />
        case 'exhibitions': return <ExhibitionsBlock {...props} />
        case 'products': return <ProductsBlock {...props} />
        case 'artworks': return <ArtworksBlock {...props} />
        case 'programs': return <ProgramsBlock {...props} />
        case 'news': return <NewsBlock {...props} />
        case 'details': return <DetailsBlock {...props} />
        case 'carousel': return <CarouselBlock {...props} />
        case 'video': return <VideoBlock {...props} />
        case 'embed': return <EmbedBlock {...props} />
        case 'book': return <BookBlock {...props} />
        default: return <div>Unknown block type: {type}</div>
    }
}

export const blockSpacing = [6, 6, 10];

export const Blocks = ({ blocks, spacing = blockSpacing, ...props }) => (
    <Stack spacing={spacing} {...props}>
        {blocks.map((block, i) => (
            <Block key={i} {...block} />
        ))}
    </Stack>
)