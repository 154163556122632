import React, { forwardRef } from "react";
import { Text } from '../Text'

export const Input = forwardRef(({ Component = Text.Body, debounce, ...props }, ref) => {
    return (
        <Component
            as="input"
            ref={ref}
            display="block"
            backgroundColor="transparent"
            borderLeft="0"
            borderTop="0"
            borderRight="0"
            borderBottomWidth="1px"
            borderBottomColor="text"
            color="text"
            width="100%"
            {...props}
        />
    )
})