import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { system } from 'styled-system';

const svg = system({
    fill: {
        property: "fill",
        scale: "colors"
    },
    strokeColor: {
        property: "stroke",
        scale: "colors"
    },
});

export const SVG = styled.svg`
    display: block;
    vector-effect: non-scaling-stroke;
    path, line, circle, rect {
        ${svg}
    }
`

const parser = new DOMParser();
export const SVGLoader = ({ url, ...props }) => {
    const [data, setData] = useState({ props: {}, contents: "" });
    useEffect(
        () => {
            fetch(url)
                .then(r => r.text())
                .then(text => {
                    const doc = parser.parseFromString(text, "image/svg+xml");
                    const svgElement = doc.querySelector("svg");
                    const props = {};
                    for (const attr of svgElement.attributes) {
                        if (attr.name === 'class') continue;
                        props[attr.name] = attr.value;
                    }
                    setData({ props, contents: svgElement.innerHTML });
                })
                .catch(e => console.log(e))
        },
        [url, setData]
    )
    return <SVG {...data.props} dangerouslySetInnerHTML={{ __html: data.contents }} {...props} />
}