import React, { useState } from "react";
import { BlockLayout } from '../BlockLayout';
import { Box, ImageFit } from 'components/Elements'

export const VideoBlock = ({ title, url, ratio = 9 / 16, preview }) => {
    const [playing, setPlaying] = useState(true);
    return (
        <BlockLayout stacked title={title}>
            <Box paddingBottom={ratio * 100 + '%'}>
                {playing ? (
                    <Box
                        as="video"
                        src={url}
                        position="absolute"
                        top="0"
                        left="0"
                        width="100%"
                        height="100%"
                        controls
                    />
                ) : (
                        <ImageFit cover containerRatio={ratio} {...preview} onClick={() => setPlaying(true)} />
                    )}

            </Box>
        </BlockLayout>
    )
}
