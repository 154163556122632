import { css } from 'styled-components/macro';

import GothamBook from '../assets/fonts/Gotham-Book.ttf';
import GothamBookItalic from '../assets/fonts/Gotham-BookItalic.ttf';
import GothamLight from '../assets/fonts/Gotham-Light.ttf';
import GothamLightItalic from '../assets/fonts/Gotham-LightItalic.ttf';
import GothamMedium from '../assets/fonts/Gotham-Medium.ttf';
import GothamMediumItalic from '../assets/fonts/Gotham-MediumItalic.ttf';
import LatingheiExtraLight from '../assets/fonts/Latinghei-Extralight.ttf';
import LatingheiDemibold from '../assets/fonts/Latinghei-Demibold.ttf';

// const CHINESE_CHARACTER_RANGE = "U+2E80-2FD5, U+3190-319f, U+3400-4DBF, U+4E00-9FCC, U+F900-FAAD, U+3000-303F";
const LATIN_CHARACTER_RANGE = "U+0000-0300, 1E00-1EFF";

export const fonts = css`
    @font-face {
        font-family: "Gotham";
        src: url(${GothamBook});
        font-weight: 400;
    }
    @font-face {
        font-family: "Gotham";
        src: url(${GothamBookItalic});
        font-weight: 400;
        font-style: italic;
    }
    @font-face {
        font-family: "Gotham";
        src: url(${GothamLight});
        font-weight: 300;
    }
    @font-face {
        font-family: "Gotham";
        src: url(${GothamLightItalic});
        font-weight: 300;
        font-style: italic;
    }
    @font-face {
        font-family: "Gotham";
        src: url(${GothamMedium});
        font-weight: 500;
    }
    @font-face {
        font-family: "Gotham";
        src: url(${GothamMediumItalic});
        font-weight: 500;
        font-style: italic;
    }

    @font-face {
        font-family: "Gotham_Latin";
        src: url(${GothamBook});
        font-weight: 400;
        unicode-range: ${LATIN_CHARACTER_RANGE}
    }
    @font-face {
        font-family: "Gotham_Latin";
        src: url(${GothamBookItalic});
        font-weight: 400;
        font-style: italic;
        unicode-range: ${LATIN_CHARACTER_RANGE}
    }
    @font-face {
        font-family: "Gotham_Latin";
        src: url(${GothamLight});
        font-weight: 300;
        unicode-range: ${LATIN_CHARACTER_RANGE}
    }
    @font-face {
        font-family: "Gotham_Latin";
        src: url(${GothamLightItalic});
        font-weight: 300;
        font-style: italic;
        unicode-range: ${LATIN_CHARACTER_RANGE}
    }
    @font-face {
        font-family: "Gotham_Latin";
        src: url(${GothamMedium});
        font-weight: 500;
        unicode-range: ${LATIN_CHARACTER_RANGE}
    }
    @font-face {
        font-family: "Gotham_Latin";
        src: url(${GothamMediumItalic});
        font-weight: 500;
        font-style: italic;
        unicode-range: ${LATIN_CHARACTER_RANGE}
    }
    @font-face {
        font-family: "Latinghei";
        src: url(${LatingheiExtraLight});
        font-weight: 400;
    }
    @font-face {
        font-family: "Latinghei";
        src: url(${LatingheiExtraLight});
        font-weight: 400;
        font-style: italic;
    }
    @font-face {
        font-family: "Latinghei";
        src: url(${LatingheiDemibold});
        font-weight: 500;
    }
    @font-face {
        font-family: "Latinghei";
        src: url(${LatingheiDemibold});
        font-weight: 500;
        font-style: italic;
    }
`