import React, { useState } from "react";
import { Stack, Link } from 'components/Elements';
import { ArtworkTypeLink } from 'components/TypeLink';
import { ImagePlaceholder } from './ImagePlaceholder';
import { TextPlaceholder } from './TextPlaceholder';

export const ArtworkThumbnail = ({ loading, title, slug, artworkType, image, artist, date, ...props }) => {
    const [loaded, onImageLoad] = useState(false);
    return (
        <Stack spacing={2} {...props}>
            <ArtworkTypeLink {...artworkType} opacity={loaded ? 1 : 0} transition="opacity .5s" />
            <Stack as={Link} display="block" to={`/psa-collections/${slug}`} spacing={2}>
                <ImagePlaceholder
                    loaded={loaded}
                    image={image}
                    ratio={2 / 3}
                    onLoad={onImageLoad}
                />
                <TextPlaceholder.Small lines={4}>
                    <TextPlaceholder.Small fontWeight={500} maxLines={1}>{loaded && title}</TextPlaceholder.Small>
                    <TextPlaceholder.Small maxLines={2}>{loaded && artist}</TextPlaceholder.Small>
                    <TextPlaceholder.Small maxLines={1}>{loaded && date}</TextPlaceholder.Small>
                </TextPlaceholder.Small>
            </Stack>
        </Stack>
    )
}