import { createGlobalStyle } from 'styled-components/macro';
import { typography } from 'styled-system';
import { reset } from './reset'
import { fonts } from './fonts'
import { animations } from './animations'
import './quill.css';

export const GlobalCSS = createGlobalStyle`
    ${reset}
    ${fonts}
    ${animations}
    * {
        animation-fill-mode: both;
        -webkit-overflow-scrolling: touch;
    }
    html {
        ${typography}
    }
    html, body, #root {
        height: 100%;
    }
    pre {
        font-family: monospace;
    }
`

GlobalCSS.defaultProps = {
    fontFamily: "default"
}