import React, { useRef, useState, useEffect } from "react";
import { Box } from '../Box';
import { resizeObserver, intersectionObserver } from 'utils/observer';
import picturefill from 'picturefill';

export const useImage = (ref, { placeholder, srcs, onLoad }) => {
    useEffect(() => {
        const element = ref.current;
        element.src = placeholder;
        const srcset = srcs.map(({ url, width }) => `${url} ${width}w`).join(', ');
        let measured = false;
        let loaded = false;
        const onLoadEvent = () => {
            const currentSrc = element.currentSrc;
            if (currentSrc !== "" && currentSrc !== placeholder && !loaded) {
                loaded = true;
                if (onLoad) onLoad(true);
                element.removeEventListener('load', onLoadEvent);
            }
        }
        if (onLoad) element.addEventListener('load', onLoadEvent);
        const onIntersectionChange = entry => {
            if (entry.isIntersecting) {
                resizeObserver.observe(element, onResize);
            } else {
                resizeObserver.unobserve(element, onResize);
            }
        }
        const onResize = entry => {
            element.setAttribute('sizes', entry.contentRect.width + 'px');
            if (!measured) {
                element.setAttribute('srcset', srcset);
                element.setAttribute('src', srcs[srcs.length - 1].url);
                measured = true;
            }
            picturefill({
                reevaluate: true,
                elements: [element]
            })
        }
        intersectionObserver.observe(element, onIntersectionChange);
        return () => {
            intersectionObserver.unobserve(element, onIntersectionChange);
            resizeObserver.unobserve(element, onResize);
            element.removeEventListener('load', onLoadEvent);
        }
    }, [placeholder, ref, srcs, onLoad])
}

export const Image = ({ placeholder, srcs, alt, ratio = 1, ...props }) => (
    <Box {...props} style={{ paddingBottom: ratio ? ratio * 100 + '%' : undefined }}>
        <ImageFill placeholder={placeholder} srcs={srcs} alt={alt} />
    </Box>
)

export const ImageFill = ({ placeholder, srcs, onLoaded, ...props }) => {
    const ref = useRef();
    useImage(ref, { placeholder, srcs, onLoaded });
    return (
        <Box
            as="img"
            ref={ref}
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="100%"
            {...props}
        />
    )
}

export const ImageFit = ({
    containerRatio,
    cover,
    ratio,
    style = {},
    position = [.5, .5],
    ...props
}) => {
    const width = (cover ? Math.max : Math.min)(1, containerRatio / ratio);
    const height = width * ratio / containerRatio;
    return (
        <ImageFill
            overflow="hidden"
            {...props}
            style={{
                width: width * 100 + '%',
                height: height * 100 + '%',
                left: (1 - width) * position[0] * 100 + '%',
                top: (1 - height) * position[1] * 100 + '%',
                ...style
            }}
        />
    )
}

export const ImageAutoFit = ({ cover, placeholder, srcs, alt, ratio, ...props }) => {
    const ref = useRef();
    const [containerRatio, setContainerRatio] = useState(undefined);
    useEffect(
        () => {
            const element = ref.current;
            const onResize = entry => setContainerRatio(entry.contentRect.height / entry.contentRect.width);
            resizeObserver.observe(element, onResize);
            return () => resizeObserver.unobserve(element, onResize);
        },
        [ref, setContainerRatio]
    );
    return (
        <Box ref={ref} overflow="hidden" {...props}>
            {containerRatio !== undefined && (
                <ImageFit
                    containerRatio={containerRatio}
                    ratio={ratio}
                    cover={cover}
                    placeholder={placeholder}
                    srcs={srcs}
                    alt={alt}
                    maxWidth="none"

                />
            )}
        </Box>
    )
}