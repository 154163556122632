import React, { useState } from "react";
import { Stack, Link } from 'components/Elements';
import { parseISO } from 'utils/date';
import { useLocalDateRangeFormatter } from 'hooks/useFormatDate';
import { ActivityTypeLink } from 'components/TypeLink';
import { ImagePlaceholder } from './ImagePlaceholder';
import { TextPlaceholder } from './TextPlaceholder';

export const ActivityThumbnail = ({ loading, title, slug, activityType, image, startDate, endDate, ...props }) => {
    const [loaded, onImageLoad] = useState(false);
    const formatDateRange = useLocalDateRangeFormatter();
    return (
        <Stack spacing={2} {...props}>
            <ActivityTypeLink {...activityType} opacity={loaded ? 1 : 0} transition="opacity .5s" />
            <Stack as={Link} display="block" to={`/whats-on/activities/${slug}`} spacing={2}>
                <ImagePlaceholder
                    loaded={loaded}
                    image={image}
                    ratio={2 / 3}
                    onLoad={onImageLoad}
                    cover
                />
                <TextPlaceholder.Small lines={[5, 4]}>
                    <TextPlaceholder.Small maxLines={[3, 2]}>
                        {loaded && title}
                    </TextPlaceholder.Small>
                    <TextPlaceholder.Small maxLines={2}>
                        {loaded && startDate && endDate && formatDateRange(parseISO(startDate), parseISO(endDate))}
                    </TextPlaceholder.Small>
                </TextPlaceholder.Small>
            </Stack>
        </Stack>
    )
}