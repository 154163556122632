import ResizeObserver from 'resize-observer-polyfill';
import 'intersection-observer';

const createObserver = (Observer, options) => {
    const observer = new Observer(entries => entries.forEach(handle), options)
    const callbacks = new Map();
    const handle = entry => {
        const cbs = callbacks.get(entry.target);
        if (cbs) cbs.forEach(cb => cb(entry));
    }
    const observe = (el, cb) => {
        if (callbacks.has(el)) {
            callbacks.set(el, [...callbacks.get(el), cb]);
        } else {
            callbacks.set(el, [cb]);
            observer.observe(el);
        }
    }
    const unobserve = (el, cb) => {
        var cbs = callbacks.get(el);
        if (cbs) {
            cbs = cbs.filter(x => x !== cb);
            if (cbs.length) {
                callbacks.set(el, cbs);
            } else {
                callbacks.delete(el);
                observer.unobserve(el);
            }
        }
    }
    return { observe, unobserve };
}

export default createObserver;

export const resizeObserver = createObserver(ResizeObserver);
export const intersectionObserver = createObserver(IntersectionObserver);