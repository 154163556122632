import { useRef, useEffect } from 'react';
import vec2 from 'utils/vec2';

export const useMouseRef = () => {
    const ref = useRef(vec2());
    useEffect(
        () => {
            const onMouseMove = e => {
                ref.current[0] = e.clientX;
                ref.current[1] = e.clientY;
            }
            window.addEventListener('mousemove', onMouseMove);
            return () => window.removeEventListener('mousemove', onMouseMove)
        },
        []
    );
    return ref;
}