import { SPECIAL_BACKGROUND_COLOR } from "config";

export const baseTheme = {
  breakpoints: ["768px", "1024px", "1200px", "1600px"],
  space: [0, 6, 12, 18, 24, 30, 36, 42, 48, 54, 60, 66, 72],
  sizes: [0, 6, 12, 18, 24, 30, 36, 42, 48, 54, 60, 66, 72],
  colors: {},
  fontSizes: [12, 14, 18, 20, 24, 28, 36],
  lineHeights: {
    tight: 1,
    display: 1.3,
    text: 1.6666,
  },
  navLinkHeight: 36,
};

export const colorThemes = {
  dark: {
    colors: {
      background: "#111",
      text: "#ECECEC",
    },
  },
  light: {
    colors: {
      background: SPECIAL_BACKGROUND_COLOR || "#ECECEC",
      text: "#000000",
    },
  },
};

export const languageThemes = {
  en: {
    fonts: {
      default: "Gotham, sans-serif",
    },
    letterSpacings: {
      default: undefined,
    },
  },
  cn: {
    fonts: {
      default: "Gotham_Latin, Latinghei, sans-serif",
    },
    letterSpacings: {
      default: "0.05em",
    },
  },
};
