import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
} from "react";
import { ThemeProvider } from "../ThemeProvider";

const ColorOverrideContext = createContext();

export const useColorOverride = colors => {
  const setOverrideColors = useContext(ColorOverrideContext);
  useEffect(() => {
    if (!colors) return;
    setOverrideColors(colors);
    return () => setOverrideColors(null);
  }, [colors, setOverrideColors]);
};

export const ColorOverrideProvider = ({ children }) => {
  const [overrideColors, setOverrideColors] = useState(null);
  const theme = useMemo(() => {
    if (overrideColors) return { colors: overrideColors };
    return {};
  }, [overrideColors]);
  return (
    <ColorOverrideContext.Provider value={setOverrideColors}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ColorOverrideContext.Provider>
  );
};
