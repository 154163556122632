import {
    format as _format,
    startOfDay as _startOfDay,
    endOfDay as _endOfDay,
    startOfWeek as _startOfWeek,
    endOfWeek as _endOfWeek,
    startOfMonth as _startOfMonth,
    endOfMonth as _endOfMonth,
} from 'date-fns';
import { enGB, zhCN } from 'date-fns/locale';
export { parse, parseISO, isDate } from 'date-fns';

const locales = { enGB, zhCN };

const wrap = fn => (...args) => {
    const locale = args.pop();
    args.push({ locale: locales[locale] });
    return fn(...args);
}

export const format = wrap(_format);
export const startOfDay = wrap(_startOfDay);
export const endOfDay = wrap(_endOfDay)
export const startOfWeek = wrap(_startOfWeek)
export const endOfWeek = wrap(_endOfWeek)
export const startOfMonth = wrap(_startOfMonth)
export const endOfMonth = wrap(_endOfMonth)
// export const formatDate = (date, locale) => format(date, "P", locale);
// export const formatTime = (date, locale) => format(date, "H:mm", locale);
// export const formatDateTime = (date, locale) => formatDate(date, locale) + ' ' + formatTime(date, locale);

// const isSameDay = (d1, d2) => (
//     d1.getFullYear() === d2.getFullYear() &&
//     d1.getMonth() === d2.getMonth() &&
//     d1.getDate() === d2.getDate()
// )
// const isSameTime = (d1, d2) => (
//     d1.getHours() === d2.getHours() &&
//     d1.getMinutes() === d2.getMinutes()
// )
// const isDayStart = date => (
//     date.getHours() === 0 &&
//     date.getMinutes() === 0
// )
// const isDayEnd = date => (
//     date.getHours() === 23 &&
//     date.getMinutes() === 59
// )

// const DASH = "—";
// export const formatDateRange = (from, to, time, locale) => {
//     if (time && isSameDay(from, to)) {
//         if (isSameTime(from, to)) {
//             if (isDayStart(from)) {
//                 return formatDate(from, locale)
//             } else {
//                 return formatDateTime(from, locale);
//             }
//         } else {
//             return `${formatDateTime(from, locale)} ${DASH} ${formatTime(to, locale)}`
//         }
//     } else {
//         if (!time || (isDayStart(from) && isDayEnd(to))) {
//             return `${formatDate(from, locale)} ${DASH} ${formatDate(to, locale)}`
//         } else {
//             return `${formatDateTime(from, locale)} ${DASH} ${formatDateTime(to, locale)}`
//         }
//     }
// }