import React from 'react';
import { VisitAndTicketsLink, APowerStoreLink, PSACollectionsLink, WhatsOnLink, MoreLink } from 'components/NavLink';
import { baseTheme } from 'styles/theme';
import { Box } from 'components/Elements';
import vec2 from 'utils/vec2';

const pct = x => x * 100 + '%';
const navLinkHeight = baseTheme.navLinkHeight;
const spacing = baseTheme.space[2];

//    0   |   1
// -------+-------
//    2   |   3
// -------+-------       6?
//        4
// -------+-------
//        5

export const PageContainer = ({ children }) => (
    <Box
        position="fixed"
        overflowY="scroll"
        overflowX="hidden"
        left={0}
        right={[navLinkHeight, null, pct(3 / 18), pct(2 / 16)]}
        top={navLinkHeight}
        bottom={navLinkHeight * 2}
        zIndex={50}
    >
        <Box px={2} width={[null, null, null, null, pct(12 / 14)]}>
            {children}
        </Box>
    </Box >
)

export const Nav = React.memo(() => (
    <>
        <VisitAndTicketsLink
            top="0"
            left="0"
            right={[navLinkHeight, null, pct(3 / 18), pct(2 / 16)]}
            height={navLinkHeight}
            paddingLeft={[null, null, pct(12 / 18), pct(12 / 16)]}
        />
        <APowerStoreLink
            top="0"
            right="0"
            width={[navLinkHeight, null, pct(3 / 18), pct(2 / 16)]}
            height={[`calc((100% - ${navLinkHeight * 2}px) / 2)`, null, navLinkHeight]}
            alignItems={[null, null, "flex-end"]}
            rotate={[true, true, false]}
        />
        <PSACollectionsLink
            top={[null, null, navLinkHeight]}
            bottom={navLinkHeight * 2}
            right="0"
            width={[navLinkHeight, null, pct(3 / 18), pct(2 / 16)]}
            height={[`calc((100% - ${navLinkHeight * 2}px) / 2)`, null, 'auto']}
            rotate={[true, true, false]}
        />
        <WhatsOnLink
            bottom={navLinkHeight}
            right="0"
            width="100%"
            paddingLeft={[0, null, pct(12 / 18), pct(12 / 16)]}
            height={navLinkHeight}
        />
        <MoreLink
            bottom="0"
            right="0"
            width="100%"
            paddingLeft={[0, null, pct(12 / 18), pct(12 / 16)]}
        />
    </>
))



//        1    
// 222222 1 333333
//        1   
// 444444444444444
//        
// 555555555555555  

export const bars = ({ ww, wh, responsive }) => [
    { // 1
        from: vec2(
            responsive([ww - navLinkHeight, null, ww * 15 / 18, ww * 14 / 16]),
            0
        ),
        to: vec2(
            responsive([ww - navLinkHeight, null, ww * 15 / 18, ww * 14 / 16]),
            wh - navLinkHeight * 2 - spacing
        )
    },
    { // 2
        from: vec2(
            spacing,
            navLinkHeight
        ),
        to: vec2(
            responsive([ww - navLinkHeight - spacing, null, ww * 15 / 18 - spacing, ww * 14 / 16 - spacing]),
            navLinkHeight
        )
    },
    { // 3
        from: vec2(
            responsive([ww - navLinkHeight + spacing, null, ww * 15 / 18 + spacing, ww * 14 / 16 + spacing]),
            responsive([(wh - navLinkHeight * 2) / 2, null, navLinkHeight])
        ),
        to: vec2(
            responsive([ww, null, ww - spacing]),
            responsive([(wh - navLinkHeight * 2) / 2, null, navLinkHeight])
        )
    },
    { // 4
        from: vec2(
            spacing,
            wh - navLinkHeight * 2
        ),
        to: vec2(
            ww - spacing,
            wh - navLinkHeight * 2
        )
    },
    { // 5
        from: vec2(
            responsive([spacing, null, ww * 12 / 18 + spacing, ww * 12 / 16 + spacing]),
            wh - navLinkHeight
        ),
        to: vec2(
            ww - spacing,
            wh - navLinkHeight
        )
    }
]