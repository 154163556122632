import React, { useState, useCallback } from "react";
import { Box, Stack, ImageFill, ImageFit, FlexGrid } from 'components/Elements';
import { useResponsiveProp } from 'hooks/useBreakpoint';

const CarouselThumbnail = ({ image, setIndex, index, ...props }) => {
    const goto = useCallback(
        () => setIndex(index),
        [setIndex, index]
    );
    const height = useResponsiveProp([42, 60]);
    const style = {
        width: height / image.ratio + 'px',
        height: height + 'px'
    }
    return (
        <Box onClick={goto} {...props} style={style}>
            <ImageFill {...image} />
        </Box>
    )
}

export const Carousel = ({ files, ...props }) => {
    const [index, setIndex] = useState(0);
    const ratio = files[0].ratio;
    return (
        <Stack {...props}>
            <Box style={{ paddingBottom: ratio * 100 + "%" }}>
                {files.map((file, i) => (
                    <ImageFit
                        key={i}
                        containerRatio={files[0].ratio}
                        position={[0, 0]}
                        style={{
                            transition: `transform .5s`,
                            transform: `translateX(${(i - index) * 100}vw)`
                        }}
                        {...file}
                    />
                ))}
            </Box>
            {files.length > 1 && (
                <FlexGrid spacing={2}>
                    {files.map((file, i) => (
                        <div key={i}>
                            <CarouselThumbnail
                                index={i}
                                setIndex={setIndex}
                                image={file}
                            />
                        </div>
                    ))}
                </FlexGrid>
            )}
        </Stack>
    )
}