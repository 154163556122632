import "core-js/stable";

import areIntlLocalesSupported from "intl-locales-supported";

const polyfillIntl = async () => {
  if (global.Intl) {
    // Determine if the built-in `Intl` has the locale data we need.
    if (!areIntlLocalesSupported(["en-GB", "zh-Hans-CN"])) {
      // `Intl` exists, but it doesn't have the data we need, so load the
      // polyfill and patch the constructors we need with the polyfill's.
      const IntlPolyfill = await import("intl");
      Intl.NumberFormat = IntlPolyfill.NumberFormat;
      Intl.DateTimeFormat = IntlPolyfill.DateTimeFormat;
    }
  } else {
    // No `Intl`, so use and load the polyfill.
    global.Intl = await import("intl");
  }
};

export default () =>
  Promise.all([
    polyfillIntl(),
    !("fetch" in window) && import("whatwg-fetch"),
    !("getOwnPropertyDescriptors" in Object) &&
      import("object.getownpropertydescriptors").then(module => module.shim()),
    !("scrollBehavior" in document.documentElement.style) &&
      import("smoothscroll-polyfill").then(module => module.polyfill()),
  ]);
