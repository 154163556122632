import React from "react";
import styled from 'styled-components/macro';
import { Text } from '../Text'

const StyledHTML = styled(Text.Body)`
    h1, h2, h3, h4, h5, h6, strong {
        font-weight: 500;
    }
    p:not(:last-child) {
        // margin-bottom: ${ ({ theme }) => theme.lineHeights.text}em;
    }
    a {
        text-decoration: underline;
    }
`

export const HTML = ({ children, ...props }) => (
    <StyledHTML dangerouslySetInnerHTML={{ __html: children }} {...props} />
)
