import React from 'react';
import { VisitAndTicketsLink, APowerStoreLink, AboutPSALink, PSACollectionsLink, WhatsOnLink } from 'components/NavLink';
import { baseTheme } from 'styles/theme';
import { Box } from 'components/Elements';
import vec2 from 'utils/vec2';

const pct = x => x * 100 + '%';
const navLinkHeight = baseTheme.navLinkHeight;
const spacing = baseTheme.space[2]

//    0   |   1
// -------+-------
//    2   |   3
// -------+-------       6?
//        4
// -------+-------
//        5

export const PageContainer = ({ children }) => (
    <Box
        position="fixed"
        overflowY="scroll"
        overflowX="hidden"
        zIndex={50}
        top={[navLinkHeight * 3, null, 0]}
        bottom={0}
        left={[0, null, pct(6 / 18), pct(4 / 16)]}
        right={0}
    >
        <Box px={2} pt={[2, 2, navLinkHeight * 3]} height="100%">
            {children}
        </Box>
    </Box >
)

export const SidebarContainer = ({ children }) => (
    <Box
        position="fixed"
        left={0}
        top={navLinkHeight * 3}
        width={[pct(1), null, pct(6 / 18), pct(4 / 16)]}
        bottom="0"
        px={2}
        pt={[2]}
    >{children}</Box>
)

export const Nav = React.memo(() => (
    <>
        <VisitAndTicketsLink
            top="0"
            left="0"
            width={[pct(1 / 2), null, pct(3 / 18), pct(2 / 16)]}
            height={navLinkHeight}
            alignItems={[null, null, "flex-end"]}
        />
        <APowerStoreLink
            top="0"
            left={[pct(1 / 2), null, pct(3 / 18), pct(2 / 16)]}
            width={[pct(1 / 2), null, pct(3 / 18), pct(2 / 16)]}
            height={navLinkHeight}
        />
        <AboutPSALink
            top={navLinkHeight}
            left="0"
            width={[pct(1 / 2), null, pct(3 / 18), pct(2 / 16)]}
            height={navLinkHeight}
        />
        <PSACollectionsLink
            top={navLinkHeight}
            left={[pct(1 / 2), null, pct(3 / 18), pct(2 / 16)]}
            width={[pct(1 / 2), null, pct(3 / 18), pct(2 / 16)]}
            height={navLinkHeight}
        />
        <WhatsOnLink
            top={navLinkHeight * 2}
            left="0"
            width={[pct(1), null, pct(6 / 18), pct(4 / 16)]}
            height={navLinkHeight}
        />
    </>
))

//        1    
// 222222 1 333333
//        1   
// 444444444444444
//        
// 555555555555555  

export const bars = ({ ww, wh, responsive }) => [
    { // 1
        from: vec2(
            responsive([ww / 2, null, ww * 3 / 18, ww * 2 / 16]),
            0
        ),
        to: vec2(
            responsive([ww / 2, null, ww * 3 / 18, ww * 2 / 16]),
            navLinkHeight * 2 - spacing
        )
    },
    { // 2
        from: vec2(
            spacing,
            navLinkHeight
        ),
        to: vec2(
            responsive([ww / 2 - spacing, null, ww * 3 / 18 - spacing, ww * 2 / 16 - spacing]),
            navLinkHeight
        )
    },
    { // 3
        from: vec2(
            responsive([ww / 2 + spacing, null, ww * 3 / 18 + spacing, ww * 2 / 16 + spacing]),
            navLinkHeight
        ),
        to: vec2(
            responsive([ww - spacing, null, ww * 6 / 18 - spacing, ww * 4 / 16 - spacing]),
            navLinkHeight
        )
    },
    { // 4
        from: vec2(
            spacing,
            navLinkHeight * 2
        ),
        to: vec2(
            responsive([ww - spacing, null, ww * 6 / 18 - spacing, ww * 4 / 16 - spacing]),
            navLinkHeight * 2
        )
    },
    { // 5
        from: vec2(
            spacing,
            navLinkHeight * 3
        ),
        to: vec2(
            responsive([ww - spacing, null, ww * 6 / 18 - spacing, ww * 4 / 16 - spacing]),
            navLinkHeight * 3
        )
    }
]