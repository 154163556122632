import React from "react";
import { Stack, Text, Link } from 'components/Elements';
import { useTranslation } from 'components/LanguageProvider';

export const Error404 = () => {
    return (
        <Stack spacing={1}>
            <Text.Title>404</Text.Title>
            <Text.Body>
                {useTranslation("errors.404")}
            </Text.Body>
            <Link to="/" textDecoration="underline">{useTranslation("errors.goHome")}</Link>
        </Stack>
    )
}
