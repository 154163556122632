import React from 'react';
import styled from 'styled-components/macro';
import { system } from 'styled-system';
import { typography } from 'styled-system';
import { Box } from '../Box';

const getLines = (x, scale, props) => {
    if (x === undefined) return undefined;
    const lineHeight = scale[props.lineHeight] || 1;
    return x * lineHeight + 'em';
}

const text = system({
    textOverflow: true,
    whiteSpace: true,
    textDecoration: true,
    lines: {
        property: "height",
        scale: "lineHeights",
        transform: getLines
    },
    maxLines: {
        property: "maxHeight",
        scale: "lineHeights",
        transform: getLines
    }
})

export const Text = styled(Box)`
    ${typography}
    ${text}
    :lang(cn) {
        font-style: normal !important;
    }
`

Text.defaultProps = {
    lineHeight: "text",
    overflow: "hidden",
    color: "text",
    fontFamily: "default",
    letterSpacing: "default"
}

export const title = {
    fontSize: [4, 4, 4, 5],
    fontWeight: 500,
    lineHeight: "display"
}
Text.Title = props => <Text as="h1" {...title} {...props} />
Text.Title.displayName = "Text.Title";

export const subheading = {
    fontSize: [2, 2, 2, 3]
}
Text.Subheading = props => <Text {...subheading} {...props} />
Text.Subheading.displayName = "Text.Subheading";

export const body = {
    fontSize: [1, 1, 1, 2]
}
Text.Body = props => <Text {...body} {...props} />
Text.Body.displayName = "Text.Body";

export const small = {
    fontSize: 0,
    lineHeight: 'text'
}
Text.Small = props => <Text {...small} {...props} />
Text.Small.displayName = "Text.Small";