import React, { useState, useCallback } from "react";
import styled from 'styled-components/macro';
import { useTransition } from 'react-spring';
import { Box, ImageFit, FlexGrid } from 'components/Elements';
import { BlockLayout } from '../BlockLayout';
import { Lightbox } from './Lightbox';
import Modal from 'react-modal';

Modal.setAppElement('#root')

const modalStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: '',
        zIndex: 100
    },
    content: {
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        border: 'none',
        background: '',
        overflow: '',
        WebkitOverflowScrolling: 'touch',
        borderRadius: '',
        outline: 'none',
        padding: ''
    }
}

const THUMBNAIL_ACTIVE_SCALE = 1.1;

const GalleryThumbnail = styled(ImageFit)`
    cursor: pointer;
    transition: transform .3s;
    &:hover {
        transform: scale(${THUMBNAIL_ACTIVE_SCALE});
    }
`

export const GalleryBlock = ({ title, images }) => {
    const imageWidth = [1 / 3 * 100 + "%", null, images.length >= 9 ? 1 / 4 * 100 + "%" : null];
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [slideIndex, setSlideIndex] = useState(0);
    const showLightbox = useCallback(
        i => {
            setSlideIndex(i);
            setLightboxOpen(true);
        },
        [setSlideIndex, setLightboxOpen]
    );
    const closeLightbox = useCallback(
        () => setLightboxOpen(false),
        [setLightboxOpen]
    );
    const transitions = useTransition(
        lightboxOpen,
        x => x,
        { from: { in: 0 }, enter: { in: 1 }, leave: { in: 0 }, config: { duration: 300 } }
    )
    return (
        <>
            <BlockLayout stacked title={title}>
                <FlexGrid spacing={2}>
                    {images.map((image, i) => (
                        <Box
                            key={i}
                            width={imageWidth}
                            paddingBottom={imageWidth}
                            onClick={() => showLightbox(i)}
                            cursor="zoom"
                        >
                            <Box inset={[1, 2, 4]} position="absolute">
                                <GalleryThumbnail
                                    containerRatio={1}
                                    position={undefined}
                                    style={{
                                        transform: lightboxOpen && i === slideIndex
                                            ? `scale(${THUMBNAIL_ACTIVE_SCALE})`
                                            : undefined
                                    }}
                                    {...image}
                                />
                            </Box>
                        </Box>
                    ))}
                </FlexGrid>
            </BlockLayout>
            {transitions.map(({ item, props, key }) => (
                <Modal key={key} onRequestClose={closeLightbox} isOpen={item} style={modalStyles}>
                    <Lightbox
                        transitionProps={props}
                        images={images}
                        slideIndex={slideIndex}
                        setSlideIndex={setSlideIndex}
                        close={closeLightbox}
                    />
                </Modal>
            ))}
            {/* {lightboxOpen && (
                <Lightbox
                    images={images}
                    slideIndex={slideIndex}
                    setSlideIndex={setSlideIndex}
                    close={closeLightbox}
                />
            )} */}
        </>
    )
}