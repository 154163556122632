import React, { useEffect, useCallback } from "react";
import { Box, AnimatedBox, Text, ImageAutoFit, Responsive } from 'components/Elements';
import { ArrowLeft, ArrowRight } from 'assets/svg/Arrow.svg'
import { X } from 'assets/svg/X.svg'

const wrap = (x, max) => {
    x %= max;
    while (x < 0) x += max;
    return x;
}

const ButtonContainer = props => (
    <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        cursor="pointer"
        {...props}
    />
)
const LightboxControlsMobile = ({ prev, next, close, caption, children, size = 10 }) => (
    <Box display="flex" flexDirection="column" position="absolute" inset={0} padding={2}>
        <Box flexGrow={1}>
            {children}
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center">
            <Text.Small>{caption}</Text.Small>
        </Box>
        <Box height={size} display="flex">
            <ButtonContainer onClick={prev} flexGrow="1">
                <ArrowLeft />
            </ButtonContainer>
            <ButtonContainer onClick={close} flexGrow="1">
                <X />
            </ButtonContainer>
            <ButtonContainer onClick={next} flexGrow="1">
                <ArrowRight />
            </ButtonContainer>
        </Box>
    </Box>
)
const LightboxControlsDesktop = ({ prev, next, close, caption, children, size = 10 }) => (
    <Box display="flex" flexDirection="column" position="absolute" inset={0}>
        <ButtonContainer height={size} onClick={close}>
            <X />
        </ButtonContainer>
        <Box flexGrow="1" display="flex" alignItems="stretch">
            <ButtonContainer width={size} onClick={prev}>
                <ArrowLeft />
            </ButtonContainer>
            <Box flexGrow={1}>
                {children}
            </Box>
            <ButtonContainer width={size} onClick={next}>
                <ArrowRight />
            </ButtonContainer>
        </Box>
        <Box height={size} display="flex" alignItems="center" justifyContent="center">
            <Text.Small>{caption}</Text.Small>
        </Box>
    </Box>
)
const LightboxContainer = ({ prev, next, close, caption, children, size = 10, transitionProps }) => (
    <AnimatedBox
        position="fixed"
        top="0"
        left="0"
        width="100%"
        height="100%"
        backgroundColor="background"
        zIndex={100}
        style={{
            opacity: transitionProps.in,
            pointerEvents: transitionProps.in.interpolate(x => x === 1 ? undefined : 'none')
        }}
    >
        <Responsive
            Components={[LightboxControlsMobile, null, LightboxControlsDesktop]}
            prev={prev}
            next={next}
            close={close}
            caption={caption}
        >
            {children}
        </Responsive>
    </AnimatedBox>
)

export const Lightbox = ({ images, slideIndex, setSlideIndex, close, transitionProps }) => {
    useEffect(
        () => {
            window.addEventListener('scroll', close);
            window.addEventListener('wheel', close);
            return () => {
                window.removeEventListener('scroll', close);
                window.removeEventListener('wheel', close);
            }
        },
        [close]
    );
    const image = images[slideIndex];
    const next = useCallback(
        () => setSlideIndex(i => wrap(i + 1, images.length)),
        [images, setSlideIndex]
    );
    const prev = useCallback(
        () => setSlideIndex(i => wrap(i - 1, images.length)),
        [images, setSlideIndex]
    );
    return (
        <LightboxContainer prev={prev} next={next} close={close} caption={image.caption} transitionProps={transitionProps}>
            {images.map((file, i) => (
                <ImageAutoFit
                    key={i}
                    position="absolute"
                    top={0}
                    left={0}
                    width="100%"
                    height="100%"
                    style={{
                        transition: `transform .5s`,
                        transform: `translateX(${(i - slideIndex) * 100}vw)`
                    }}
                    {...file}
                />
            ))}
        </LightboxContainer>
    )
}