import { useEffect } from 'react';

export const useAnimationFrame = (fn) => {
    useEffect(
        () => {
            let frame = null;
            let then = Date.now();
            const tick = () => {
                frame = requestAnimationFrame(tick);
                const now = Date.now();
                fn(now - then);
                then = now;
            }
            tick();
            return () => cancelAnimationFrame(frame);
        },
        [fn]
    );
}