import React from 'react';
import { SVG } from 'components/Elements';


export const Moon = props => (
    <SVG width="21px" height="21px" viewBox="0 0 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="text">
        <g id="Artboard-Copy" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M3.05394593,17.1506535 C4.16220305,17.7773556 5.44268517,18.1350474 6.8067107,18.1350474 C11.023431,18.1350474 14.4417581,14.7167203 14.4417581,10.5 C14.4417581,6.28327974 11.023431,2.86495257 6.8067107,2.86495257 C5.44268517,2.86495257 4.16220305,3.22264442 3.05394593,3.84934652 C4.75112186,2.15953782 7.09136167,1.11507519 9.67554881,1.11507519 C14.8586997,1.11507519 19.0604736,5.31684915 19.0604736,10.5 C19.0604736,15.6831509 14.8586997,19.8849248 9.67554881,19.8849248 C7.09136167,19.8849248 4.75112186,18.8404622 3.05394593,17.1506535 Z" id="Combined-Shape" fill="#000000"></path>
        </g>
    </SVG>
)