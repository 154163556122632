import validate from "./validate";

const cache = {};
const cacheGet = (path, language) => {
  if (!(language in cache)) return null;
  if (!(path in cache[language])) return null;
  return cache[language][path];
};
const cacheSet = (path, language, data) => {
  if (!(language in cache)) cache[language] = {};
  cache[language][path] = data;
};

export const getFullURL = path => process.env.REACT_APP_API_BASE_URL + path;

export default (path, language) => {
  const url = getFullURL(path);
  const cached = cacheGet(path, language);
  if (cached) return Promise.resolve(cached);
  return fetch(url, { headers: { "X-Language": language } })
    .then(r => {
      if (!r.ok) {
        const error = new Error(
          `Request to ${url} failed with error ${r.status} ${r.statusText}`
        );
        error.name = r.status;
        throw error;
      }
      return r;
    })
    .then(r => r.json())
    .then(data => {
      if (process.env.NODE_ENV === "development")
        console.log(`API: loaded ${url}`, data);
      validate(path, data);
      cacheSet(path, language, data);
      return data;
    });
};
