import React from "react";
import styled from 'styled-components/macro';
import { system } from 'styled-system';
import { Link, Text, Box } from 'components/Elements';
import { LanguageToggle, useTranslation } from 'components/LanguageProvider';
import { DarkModeToggle } from 'components/DarkModeProvider'
import { baseTheme } from 'styles/theme';

const rotate = system({
    rotate: {
        property: 'transform',
        transform: enabled => enabled
            ? `rotate(-90deg) translateX(-100%)`
            : 'none'
    }
})

const LinkText = styled(Text.Small)`
    white-space: nowrap;
    overflow: visible;
    display: inline-flex;
    transform-origin: 0 0;
    height: auto;
    line-height: 1;
    font-weight: 500;
    height: ${ ({ theme }) => theme.navLinkHeight}px;
    padding-left: ${ ({ theme }) => theme.space[2]}px;
    padding-right: ${ ({ theme }) => theme.space[2]}px;
    align-items: center;
    ${rotate}
`

const LinkContainer = ({ children, ...props }) => (
    <Box
        position="fixed"
        backgroundColor="background"
        display="flex"
        alignItems='stretch'
        justifyContent='stretch'
        overflow="hidden"
        transition="background-color .5s"
        {...props}
    >
        {children}
    </Box>
)

const NavLink = ({ to, alignItems, rotate, children, ...props }) => (
    <LinkContainer {...props}>
        <Link to={to} width="100%" height="100%" display="flex" alignItems={alignItems}>
            <LinkText rotate={rotate}>{children}</LinkText>
        </Link>
    </LinkContainer>
)

export const VisitAndTicketsLink = props => (
    <NavLink to="/visit-and-tickets" {...props}>
        {useTranslation("visitAndTickets.title")}
    </NavLink>
)

export const APowerStoreLink = props => (
    <NavLink to="/publication" {...props}>
        {useTranslation("aPowerStore.title")}
    </NavLink>
)

export const AboutPSALink = props => (
    <NavLink to="/about-psa" {...props}>
        {useTranslation("aboutPSA.title")}
    </NavLink>
)

export const PSACollectionsLink = props => (
    <NavLink to="/psa-collections" {...props}>
        {useTranslation("psaCollections.title")}
    </NavLink>
)

export const WhatsOnLink = props => (
    <NavLink to="/whats-on" {...props}>
        {useTranslation("whatsOn.title")}
    </NavLink>
)

export const MoreLink = ({ to, rotate, children, ...props }) => (
    <LinkContainer {...props}>
        <Link to="/more" width="100%" height="100%">
            <LinkText rotate={rotate}>
                {useTranslation("more.title")}
            </LinkText>
        </Link>
        <DarkModeToggle height={baseTheme.navLinkHeight} px={1} fontSize={3} />
        <LanguageToggle height={baseTheme.navLinkHeight} pl={1} pr={2} fontSize={3} />
    </LinkContainer>
)