import styled from 'styled-components/macro';
import { system } from 'styled-system';
import { get } from 'styled-system';
import { Stack } from '../Stack';

const flexContainer = system({
    gutter: {
        properties: ["marginLeft", "marginRight"],
        scale: "space",
        transform: (x, scale) => get(scale, x, x) * -.5 + 'px'
    },
    spacing: {
        property: "marginBottom",
        scale: "space",
        transform: (x, scale) => get(scale, x, x) * -1 + 'px'
    }
});

const flexItem = system({
    gutter: {
        properties: ["paddingLeft", "paddingRight"],
        scale: "space",
        transform: (x, scale) => get(scale, x, x) * .5 + 'px'
    },
    spacing: {
        property: 'marginBottom',
        scale: 'space'
    }
});

export const FlexGrid = styled(Stack)`
    ${flexContainer}
    & > * {
        ${flexItem}
    }
`;

FlexGrid.defaultProps = {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    spacing: 0,
    gutter: [2, 2, 4]
}