import React from "react";
import { BlockLayout } from '../BlockLayout';
import { Box } from 'components/Elements'

export const EmbedBlock = ({ title, url, ratio = 9 / 16 }) => (
    <BlockLayout stacked title={title}>
        <Box paddingBottom={ratio * 100 + '%'}>
            <Box
                as="iframe"
                src={url}
                position="absolute"
                top="0"
                left="0"
                width="100%"
                height="100%"
            ></Box>
        </Box>
    </BlockLayout>
);
