import React, { createContext, useCallback, useContext, useMemo } from "react";
import { ThemeContext } from 'styled-components';
import { Btn } from '../Elements';
import { ThemeProvider } from '../ThemeProvider';
import { colorThemes } from 'styles/theme';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { Sun } from 'assets/svg/Sun.svg'
import { Moon } from 'assets/svg/Moon.svg'

const DarkModeContext = createContext();

export const DarkModeToggle = props => {
    const [darkMode, toggleDarkMode] = useContext(DarkModeContext);
    if (useContext(ThemeContext).isOverrideColors) return null;
    return (
        <Btn onClick={toggleDarkMode} {...props}>
            {darkMode ? <Sun /> : <Moon />}
        </Btn>
    )
}

export const DarkModeProvider = ({ children }) => {
    const [darkMode, setDarkMode] = useLocalStorage("darkMode", false);
    const toggleDarkMode = useCallback(
        () => setDarkMode(x => !x),
        [setDarkMode]
    );
    const value = useMemo(
        () => [darkMode, toggleDarkMode],
        [darkMode, toggleDarkMode]
    )
    return (
        <DarkModeContext.Provider value={value}>
            <ThemeProvider theme={darkMode ? colorThemes.dark : colorThemes.light}>
                {children}
            </ThemeProvider>
        </DarkModeContext.Provider>
    )
}