import React from 'react';
import { SVG } from 'components/Elements';

export const ArrowLeft = props => (
    <SVG width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M5.99953 1L1.03809 5.96144M5.99953 13C3.77923 10.7797 3.16146 10.1619 1.03809 8.03856" stroke="black" />
        <line x1="1.89941" y1="7" x2="17.9998" y2="7" stroke="black" />
    </SVG>
)

ArrowLeft.defaultProps = {
    strokeColor: "text"
}

export const ArrowRight = props => (
    <SVG width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M12 13L16.9614 8.03856M12 1C14.2203 3.22029 14.8381 3.83807 16.9614 5.96144" stroke="black" />
        <line x1="16.1004" y1="7" x2="5.63462e-06" y2="7" stroke="black" />
    </SVG>
)

ArrowRight.defaultProps = {
    strokeColor: "text"
}

export const ArrowDown = props => (
    <SVG width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M1 12L5.96144 16.9614M13 12C10.7797 14.2203 10.1619 14.8381 8.03856 16.9614" stroke="black" />
        <line x1="7" y1="16.1003" x2="7" y2="-1.9292e-06" stroke="black" />
    </SVG>
)

ArrowDown.defaultProps = {
    strokeColor: "text"
}

export const ArrowUp = props => (
    <SVG width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M13 6L8.03856 1.03856M1 6C3.22029 3.7797 3.83807 3.16193 5.96144 1.03856" stroke="black" />
        <line x1="7" y1="1.89965" x2="7" y2="18" stroke="black" />
    </SVG>
)

ArrowUp.defaultProps = {
    strokeColor: "text"
}