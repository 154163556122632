import React from "react";
import { Box } from 'components/Elements';
import { Error404 } from 'components/Error404';

export const Error = ({ name, message, children }) => {
    if (name === 404) return <Error404 />
    return (
        <Box as="pre" color="red" fontFamily="monospace">
            {"Error!\n"}
            {children}
        </Box>
    )
}
