import { useState } from 'react';

const get = key => {
    try {
        return JSON.parse(window.localStorage.getItem(key));
    } catch (e) { }
    return null;
}
const set = (key, value) => value !== undefined
    ? localStorage.setItem(key, JSON.stringify(value))
    : localStorage.removeItem(key);

export const useLocalStorage = (key, defaultValue) => {
    const [value, setValue] = useState(() => {
        const value = get(key);
        if (value !== null) return value;
        if (defaultValue === undefined) return null;
        if (typeof defaultValue === 'function') return defaultValue();
        return defaultValue;
    });
    const updateValue = newValue => {
        if (typeof newValue === 'function') newValue = newValue(value);
        set(key, newValue);
        setValue(newValue);
    }
    return [value, updateValue];
}
