import { format } from 'utils/date';
import { isSameDay } from 'date-fns';
import { useTranslation } from "components/LanguageProvider"

// export const useDateFormatter = string => {
//     const locale = useTranslation("_dateLocale");
//     return date => format(date, string, locale);
// }

export const useDateFn = dateFn => {
    const locale = useTranslation("_dateLocale");
    return (...args) => dateFn(...args, locale);
}

export const useDateFormatter = () => useDateFn(format)

export const useFormatDate = (date, string) => {
    const format = useDateFormatter();
    try {
        return format(date, string);
    } catch (e) {
        return e.message;
    }
}

export const useLocalDateFormatter = () => {
    const format = useDateFormatter();
    const dateFormat = useTranslation("_dateFormat");
    return date => format(date, dateFormat);
}

const TIME_FORMAT = "kk:mm";
const DASH = "—";

export const useLocalDateRangeFormatter = () => {
    const format = useDateFormatter();
    const dateFormat = useTranslation("_dateFormat");
    return (from, to, withTime = true) => {
        try {
            if (isSameDay(from, to)) {
                if (!withTime) return format(from, dateFormat);
                return `${format(from, dateFormat)} ${format(from, TIME_FORMAT)} ${DASH} ${format(to, TIME_FORMAT)}`;
            } else {
                return `${format(from, dateFormat)} ${DASH} ${format(to, dateFormat)}`;
            }
        } catch (e) {
            return e.message;
        }
    }
}