import React from "react";
import { Text } from 'components/Elements';

export const TextPlaceholder = ({ lines, maxLines, children, ...props }) => (
    <Text
        maxLines={maxLines}
        lines={lines}
        textOverflow={maxLines === 1 || lines === 1 ? 'ellipsis' : undefined}
        transition="opacity .2s"
        opacity={children ? 1 : 0}
        {...props}
    >{children}</Text>
)

TextPlaceholder.Small = props => <TextPlaceholder as={Text.Small} {...props} />
TextPlaceholder.Body = props => <TextPlaceholder as={Text.Body} {...props} />
TextPlaceholder.Title = props => <TextPlaceholder as={Text.Title} {...props} />