import { useState } from 'react';
import { useSpring } from 'react-spring';
import range from 'lodash/range';

export const useSlideTransition = (target, max = Infinity, options = {}) => {
    const [curr, setCurr] = useState(target);
    const spring = useSpring({
        target,
        onRest: ({ target }) => setCurr(target),
        ...options
    });
    const from = Math.min(curr, target);
    const to = Math.max(curr, target);
    return range(from, to + 1).map(index => ({
        index,
        t: spring.target.interpolate(t => index - t)
    }))
}