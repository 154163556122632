import React from 'react';
import { VisitAndTicketsLink, AboutPSALink, PSACollectionsLink, WhatsOnLink, MoreLink } from 'components/NavLink';
import { Box } from 'components/Elements';
import { baseTheme } from 'styles/theme';
import vec2 from 'utils/vec2';

const pct = x => x * 100 + '%';
const navLinkHeight = baseTheme.navLinkHeight;
const spacing = baseTheme.space[2];

//    0   |   1
// -------+-------
//    2   |   3
// -------+-------       6?
//        4
// -------+-------
//        5

export const PageContainer = ({ children }) => (
    <Box
        position="fixed"
        overflowY="scroll"
        overflowX="hidden"
        right={0}
        top={0}
        zIndex={50}
        left={[navLinkHeight, null, pct(3 / 18), pct(2 / 16)]}
        bottom={navLinkHeight * 3}
    >
        <Box px={2} pt={[2, 2, 2, 4]} pb={6}>
            {children}
        </Box>
    </Box >
)

export const Nav = React.memo(() => (
    <>
        <VisitAndTicketsLink
            top="0"
            left="0"
            width={[navLinkHeight, null, pct(3 / 18), pct(2 / 16)]}
            height={[`calc((100% - ${navLinkHeight * 2}px) / 2)`, null, 'auto']}
            bottom={[null, null, navLinkHeight * 3]}
            alignItems={["flex-start", null, "flex-end"]}
            rotate={[true, true, false]}
        />
        <AboutPSALink
            bottom={navLinkHeight * 2}
            left="0"
            width={[navLinkHeight, null, pct(3 / 18), pct(2 / 16)]}
            height={[`calc((100% - ${navLinkHeight * 2}px) / 2)`, null, navLinkHeight]}
            alignItems={["flex-start", null, "flex-end"]}
            rotate={[true, true, false]}
        />
        <PSACollectionsLink
            bottom={navLinkHeight * 2}
            right={0}
            left={[navLinkHeight, null, pct(3 / 18), pct(2 / 16)]}
            height={navLinkHeight}
            paddingRight={[null, null, pct(12 / 18), pct(12 / 16)]}
        />
        <WhatsOnLink
            bottom={navLinkHeight}
            right="0"
            width="100%"
            paddingRight={[0, null, pct(12 / 18), pct(12 / 16)]}
            height={navLinkHeight}
        />
        <MoreLink
            bottom="0"
            right="0"
            width="100%"
            paddingRight={[0, null, pct(12 / 18), pct(12 / 16)]}
        />
    </>
))


//        1    
// 222222 1 333333
//        1   
// 444444444444444
//        
// 555555555555555  

export const bars = ({ ww, wh, responsive }) => [
    { // 1
        from: vec2(
            responsive([navLinkHeight, null, ww * 3 / 18, ww * 2 / 16]),
            0
        ),
        to: vec2(
            responsive([navLinkHeight, null, ww * 3 / 18, ww * 2 / 16]),
            wh - navLinkHeight * 2 - spacing
        )
    },
    { // 2
        from: vec2(
            responsive([0, null, spacing]),
            responsive([(wh - navLinkHeight * 2) / 2, null, wh - navLinkHeight * 3])
        ),
        to: vec2(
            responsive([navLinkHeight - spacing, null, ww * 3 / 18 - spacing, ww * 2 / 16 - spacing]),
            responsive([(wh - navLinkHeight * 2) / 2, null, wh - navLinkHeight * 3])
        )
    },
    { // 3
        from: vec2(
            responsive([navLinkHeight + spacing, null, ww * 3 / 18 + spacing, ww * 2 / 16 + spacing]),
            wh - navLinkHeight * 3
        ),
        to: vec2(
            ww - spacing,
            wh - navLinkHeight * 3
        )
    },
    { // 4
        from: vec2(
            spacing,
            wh - navLinkHeight * 2
        ),
        to: vec2(
            responsive([ww - spacing, null, ww * 6 / 18 - spacing, ww * 4 / 16 - spacing]),
            wh - navLinkHeight * 2
        )
    },
    { // 5
        from: vec2(
            spacing,
            wh - navLinkHeight
        ),
        to: vec2(
            responsive([ww - spacing, null, ww * 6 / 18 - spacing, ww * 4 / 16 - spacing]),
            wh - navLinkHeight
        )
    }
]