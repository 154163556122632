import React from "react";
import { Box, Responsive } from 'components/Elements';
import { ArrowLeft, ArrowRight } from 'assets/svg/Arrow.svg'

const inset = [4, 4, 6, 8];

const Background = React.memo(() => <Box position="absolute" inset={0} bg="text" opacity="0.1" />)

const ButtonContainer = ({ onClick, children, ...props }) => (
    <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        cursor={onClick ? "pointer" : undefined}
        onClick={onClick || undefined}
        {...props}
    >
        {onClick && children}
    </Box>
)

const BookContainerWide = ({ prev, next, children, ...props }) => (
    <Box display="flex" {...props}>
        <Background />
        <ButtonContainer width={inset} onClick={prev}>
            <ArrowLeft />
        </ButtonContainer>
        <Box flexGrow={1} py={inset}>
            {children}
        </Box>
        <ButtonContainer width={inset} onClick={next}>
            <ArrowRight />
        </ButtonContainer>
    </Box>
)

const BookContainerTall = ({ prev, next, children, ...props }) => (
    <Box display="flex" flexWrap="wrap" {...props}>
        <Box width="100%" pt={2}>
            {children}
        </Box>
        <ButtonContainer onClick={prev} width="50%" height={6}>
            <ArrowLeft />
        </ButtonContainer>
        <ButtonContainer onClick={next} width="50%" height={6}>
            <ArrowRight />
        </ButtonContainer>
    </Box>
)

export const BookContainer = props =>
    <Responsive Components={[BookContainerTall, null, BookContainerWide]} {...props} />