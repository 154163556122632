import React from "react";
import { Box, Image } from 'components/Elements';

const shadowGradient = "rgba(0, 0, 0, 0.1) 0%, transparent 100%";
const shadowGradientLeft = `linear-gradient(to left, ${shadowGradient})`;
const shadowGradientRight = `linear-gradient(to right, ${shadowGradient})`;
const Shadow = ({ right }) => (
    <Box
        position="absolute"
        top={0}
        right={right ? undefined : 0}
        left={right ? 0 : undefined}
        height="100%"
        width={4}
        backgroundImage={right ? shadowGradientRight : shadowGradientLeft}
    />
)

export const Spread = ({ left, right }) => (
    <Box display="flex" position="absolute" inset={0}>
        <Box width="50%">
            {left && (
                <>
                    <Image {...left} backgroundColor="white" />
                    <Shadow />
                </>
            )}
        </Box>
        <Box width="50%">
            {right && (
                <>
                    <Image {...right} backgroundColor="white" />
                    <Shadow right />
                </>
            )}
        </Box>
    </Box>
)