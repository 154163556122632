import React, { useContext, useMemo } from "react";
import {
  ThemeProvider as BaseThemeProvider,
  ThemeContext,
} from "styled-components/macro";
import mergeWith from "lodash/mergeWith";

const mergeThemes = (base, source) => {
  return mergeWith({}, base, source, (baseValue, sourceValue) => {
    if (Array.isArray(sourceValue)) return sourceValue;
  });
};

export const ThemeProvider = ({ theme = {}, children }) => {
  const baseTheme = useContext(ThemeContext) || {};
  const mergedTheme = useMemo(() => mergeThemes(baseTheme, theme), [
    baseTheme,
    theme,
  ]);
  return <BaseThemeProvider theme={mergedTheme}>{children}</BaseThemeProvider>;
};
