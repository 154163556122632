import React, { useState } from "react";
import { Stack, Link } from 'components/Elements';
import { parseISO } from 'utils/date';
import { useLocalDateRangeFormatter } from 'hooks/useFormatDate';
import { ImagePlaceholder } from './ImagePlaceholder';
import { TextPlaceholder } from './TextPlaceholder';

export const ProgramThumbnail = ({ loading, title, slug, image, startDate, endDate, ...props }) => {
    const [loaded, onImageLoad] = useState(false);
    const formatDateRange = useLocalDateRangeFormatter();
    return (
        <Stack as={Link} display="block" to={`/whats-on/programs/${slug}`} spacing={2} {...props}>
            <ImagePlaceholder
                loaded={loaded}
                onLoad={onImageLoad}
                image={image}
                ratio={Math.sqrt(2)}
                cover
            />
            <TextPlaceholder.Small lines={[5, 4]}>
                <TextPlaceholder.Small maxLines={[3, 2]}>
                    {title}
                </TextPlaceholder.Small>
                <TextPlaceholder.Small maxLines={2}>
                    {startDate && endDate && formatDateRange(parseISO(startDate), parseISO(endDate))}
                </TextPlaceholder.Small>
            </TextPlaceholder.Small>
        </Stack>
    )
}