import { useEffect } from "react";
import { useLocation } from "react-router-dom";

var _hmt = window._hmt || [];
(function () {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?a108b93dd86f40f2f1f7c9472c57ea89";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();

export const Analytics = () => {
  const location = useLocation();
  useEffect(() => {
    _hmt.push(["_trackPageview", location.pathname]);
  }, [location]);
  return null;
};
