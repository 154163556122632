import React, { createContext, useContext, useMemo, useEffect } from 'react'
import get from 'lodash/get';
import { ThemeProvider } from '../ThemeProvider'
import { languageThemes } from 'styles/theme';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { getUserLocales } from 'get-user-locale';
import { format } from 'date-fns';

const getDefaultLocale = () => {
    const locales = getUserLocales();
    for (const locale of locales) {
        if (locale.startsWith('cn')) return 'cn';
        if (locale.startsWith('en')) return 'en';
    }
    return 'cn';
}

const LanguageContext = createContext();

export const useLanguage = () => useContext(LanguageContext).language;
export const useSetLanguage = () => useContext(LanguageContext).setLanguage;

export const useTranslator = () => {
    const { language: currentLanguage, languages, defaultLanguage } = useContext(LanguageContext);
    return (path, language = currentLanguage) => {
        const result = get(languages[language], path);
        if (!result) {
            console.warn(`No translation for "${path}" in language ${language}`);
            return get(languages[defaultLanguage], path) || path;
        }
        return result;
    }
}
export const useTranslation = (key, languageKey) => useTranslator()(key, languageKey);

export const useDateFn = dateFn => {
    const locale = useTranslation("_dateLocale");
    return (...args) => dateFn(...args, locale);
}
export const useDateFormatter = () => useDateFn(format);
export const useFormatDate = date => useDateFormatter()(date);

export const LanguageProvider = ({ languages, defaultLanguage, children }) => {
    const [language, setLanguage] = useLocalStorage('language', getDefaultLocale);
    const value = useMemo(
        () => ({ language, setLanguage, defaultLanguage, languages }),
        [language, languages, defaultLanguage, setLanguage]
    );
    const isRoot = useContext(LanguageContext) === undefined;
    useEffect(
        () => {
            if (!isRoot) return;
            document.documentElement.setAttribute('lang', language);
            return () => document.documentElement.removeAttribute('lang');
        },
        [language, isRoot]
    )
    return (
        <LanguageContext.Provider value={value}>
            <ThemeProvider theme={languageThemes[language]}>
                {children}
            </ThemeProvider>
        </LanguageContext.Provider>
    );
}