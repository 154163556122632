import React from "react";
import styled from "styled-components/macro";
import { Box } from "components/Elements";
import { useLocation, matchPath } from "react-router-dom";
import { useSpring, config, animated } from "react-spring";
import { useBreakpoint, getResponsiveProp } from "hooks/useBreakpoint";
import useWindowSize from "hooks/useWindowSize";
import { useAnimationFrame } from "hooks/useAnimationFrame";
import { useMouseRef } from "hooks/useMouseRef";
import vec2 from "utils/vec2";
import range from "lodash/range";

import { bars as splashBars } from "components/Layouts/SplashLayout";
import { bars as visitAndTicketsBars } from "components/Layouts/VisitAndTicketsLayout";
import { bars as aPowerStoreBars } from "components/Layouts/APowerStoreLayout";
import { bars as aboutPSABars } from "components/Layouts/AboutPSALayout";
import { bars as PSACollectionsBars } from "components/Layouts/PSACollectionsLayout";
import { bars as whatsOnBars } from "components/Layouts/WhatsOnLayout";
import { bars as moreBars } from "components/Layouts/MoreLayout";
import { SHOW_SPECIAL_ANIMATION } from "../../config";

const getBars = (pathname, arg) => {
  if (matchPath(pathname, { path: "/", exact: true }))
    return { key: "splash", bars: splashBars(arg) };
  if (matchPath(pathname, { path: "/visit-and-tickets" }))
    return { key: "visitAndTickets", bars: visitAndTicketsBars(arg) };
  if (matchPath(pathname, { path: "/publication" }))
    return { key: "aPowerStore", bars: aPowerStoreBars(arg) };
  if (matchPath(pathname, { path: "/about-psa" }))
    return { key: "aboutPSA", bars: aboutPSABars(arg) };
  if (matchPath(pathname, { path: "/psa-collections" }))
    return { key: "PSACollections", bars: PSACollectionsBars(arg) };
  if (matchPath(pathname, { path: "/whats-on" }))
    return { key: "whatsOn", bars: whatsOnBars(arg) };
  if (matchPath(pathname, { path: "/more" }))
    return { key: "more", bars: moreBars(arg) };
  return { key: "404", bars: [] };
};

const BarLine = styled(animated.line)`
  stroke: ${({ theme }) => theme.colors.text};
`;

const Bar = ({ from, to, width = 2, pushPower, pushDist }) => {
  const mouseRef = useMouseRef();
  const [x1, y1] = from;
  const [x2, y2] = to;
  const attrs = { x1, y1, x2, y2, strokeWidth: width };
  const [props, set] = useSpring(() => ({
    from: attrs,
    ...attrs,
    config: config.gentle,
  }));
  useAnimationFrame(() => {
    const line = pushLine(
      { from, to, width },
      { pushPower, pushDist, mouse: mouseRef.current }
    );
    set({
      x1: line.from[0],
      y1: line.from[1],
      x2: line.to[0],
      y2: line.to[1],
      strokeWidth: line.width,
    });
  });
  return <BarLine {...props} />;
};

const pushPoint = (pt, mouse, maxDist, power) => {
  const d = vec2.sub(mouse, pt);
  const dist = vec2.len(d);
  const force = Math.pow(Math.max(maxDist - dist, 0) / maxDist, 3);
  const push = vec2.scale(vec2.normalize(d, dist), force * power * -1);
  return push;
};

const pushLinePoint = (point, forces, offset) => {
  let pushed = point;
  forces.forEach((f, i) => {
    const strength = (forces.length - Math.abs(i - offset)) / forces.length;
    pushed = vec2.add(pushed, vec2.scale(f, strength));
  });
  return pushed;
};

const pushLine = ({ from, to, width }, { mouse, pushDist, pushPower }) => {
  const dist = vec2.dist(from, to);
  const segments = Math.floor(dist / 10);
  const points = range(0, segments + 1).map(i =>
    vec2.lerp(from, to, i / segments)
  );
  const forces = points.map(pt => pushPoint(pt, mouse, pushDist, pushPower));
  return {
    width,
    from: pushLinePoint(from, forces, 0),
    to: pushLinePoint(to, forces, segments),
  };
};

export const Bars = React.memo(({ pushDist = 400 }) => {
  const [ww, wh] = useWindowSize();
  const breakpoint = useBreakpoint();
  const location = useLocation();
  const responsive = arr => getResponsiveProp(arr, breakpoint);
  const { bars, key } = getBars(location.pathname, { ww, wh, responsive });
  const pushPower = key === "splash" ? 10 : 0.7;
  return (
    <Box
      as="svg"
      position="fixed"
      top={0}
      left={0}
      width="100%"
      height="100%"
      pointerEvents="none"
      zIndex={20}
      opacity={SHOW_SPECIAL_ANIMATION && key === "splash" ? 0 : 1}
      transition="opacity .2s"
    >
      {bars.map((bar, i) => (
        <Bar
          key={String(i)}
          pushPower={pushPower}
          pushDist={pushDist}
          {...bar}
        />
      ))}
    </Box>
  );
});
