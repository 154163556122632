import styled, { css } from 'styled-components/macro'
import { variant } from 'styled-system';
import { box } from '../Box';

const touch = 'ontouchstart' in window;

const buttonVariants = variant({
    variants: {
        large: {
            px: ['12px', '12px', '22px'],
            height: ['36px', null, '60px'],
            fontSize: [2, null, 3]
        }
    }
})

export const Btn = styled.button`
    white-space: nowrap;
    background-color: transparent;
    display: inline-flex;
    align-items: center;
    outline: 0;
    ${props => !props.disabled && "cursor: pointer;"}
    font-family: inherit;
    border: none;
    margin: 0;
    padding: 0;
    ${box}
`

Btn.defaultProps = { color: 'text' };

export const Button = styled(Btn)`
    transition: color .2s, background-color .2s;
    border: 1px solid ${({ theme }) => theme.colors.text};
    color: ${({ theme }) => theme.colors.text};
    ${props => (
        props.hover && !props.disabled && !('ontouchstart' in window) && (
            css`
                &:hover {
                    background-color: ${({ theme }) => theme.colors.text};
                    color: ${({ theme }) => theme.colors.background};
                }
            `
        )
    )}
    ${({ selected, theme }) => selected
        ? css`
            background-color: ${theme.colors.text};
            color: ${theme.colors.background};
        `
        : ''
    }
    ${buttonVariants}
`

Button.defaultProps = {
    px: touch ? '12px' : '9px',
    fontSize: 0,
    height: touch ? '28px' : '24px',
    hover: true
}