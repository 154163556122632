import React, { useState } from "react";
import { Stack, Link } from 'components/Elements';
// import { ProductTypeLink } from 'components/TypeLink';
import { ImagePlaceholder } from './ImagePlaceholder';
import { TextPlaceholder } from './TextPlaceholder';

export const ProductThumbnail = ({ loading, title, slug, productType, image, price, ...props }) => {
    const [loaded, onImageLoad] = useState(false);
    return (
        <Stack spacing={2} {...props}>
            {/* <ProductTypeLink {...productType} opacity={loaded ? 1 : 0} transition="opacity .2s" /> */}
            <Stack as={Link} display="block" to={`/publication/${slug}`} spacing={2}>
                <ImagePlaceholder
                    ratio={2 / 3}
                    loaded={loaded}
                    image={image}
                    onLoad={onImageLoad}
                />
                <TextPlaceholder.Small lines={[3, 4]}>
                    <TextPlaceholder.Small maxLines={[3, 2]}>
                        {loaded && title}
                    </TextPlaceholder.Small>
                    <TextPlaceholder.Small lines={1}>
                        {loaded && price && "¥" + price.toFixed(2)}
                    </TextPlaceholder.Small>
                </TextPlaceholder.Small>
            </Stack>
        </Stack>
    )
};