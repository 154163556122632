import styled from 'styled-components/macro';
import { system, compose } from 'styled-system';
import { space, flexbox, layout, position, color, border, background, typography } from 'styled-system';

const inset = system({
    inset: {
        properties: ["top", "left", "bottom", "right"],
        scale: "space"
    }
})

export const box = compose(
    space,
    flexbox,
    layout,
    position,
    color,
    border,
    inset,
    background,
    typography,
    system({ transition: true, cursor: true, visibility: true })
)

export const Box = styled.div`
    ${box}
`