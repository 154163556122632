import { useState, useEffect, useContext } from 'react';
import { ThemeContext } from 'styled-components';

const getBreakpoint = widths => {
    for (let i = widths.length - 1; i >= 0; i--) {
        const mq = window.matchMedia(`(min-width: ${widths[i]})`);
        if (mq.matches) return i + 1;
    }
    return 0;
}

export const useBreakpoint = () => {
    const { breakpoints } = useContext(ThemeContext);
    const [currBreakpoint, setCurrBreakpoint] = useState(() => getBreakpoint(breakpoints));
    useEffect(
        () => {
            const onResize = () => {
                const breakpoint = getBreakpoint(breakpoints);
                if (breakpoint !== currBreakpoint) setCurrBreakpoint(breakpoint);
            }
            window.addEventListener('resize', onResize);
            return () => window.removeEventListener('resize', onResize);
        },
        [currBreakpoint, setCurrBreakpoint, breakpoints]
    )
    return currBreakpoint;
}

export const getResponsiveProp = (arr, breakpoint) => {
    if (!Array.isArray(arr)) return arr;
    let value = arr[Math.min(breakpoint, arr.length - 1)];
    while (value === null && breakpoint > 0) value = arr[--breakpoint];
    return value;
}

export const useResponsiveProp = arr => getResponsiveProp(arr, useBreakpoint());