import React, { forwardRef } from "react";
import { ActivityThumbnail } from './ActivityThumbnail';
import { ExhibitionThumbnail } from './ExhibitionThumbnail';
import { ArtworkThumbnail } from './ArtworkThumbnail';
import { ProductThumbnail } from './ProductThumbnail';
import { NewsThumbnail } from './NewsThumbnail';
import { ProgramThumbnail } from './ProgramThumbnail';


export const Thumbnail = forwardRef(({ type, ...props }, ref) => {
    switch (type) {
        case 'activity': return <ActivityThumbnail ref={ref} {...props} />
        case 'exhibition': return <ExhibitionThumbnail ref={ref} {...props} />
        case 'artwork': return <ArtworkThumbnail ref={ref} {...props} />
        case 'product': return <ProductThumbnail ref={ref} {...props} />
        case 'news': return <NewsThumbnail ref={ref} {...props} />
        case 'program': return <ProgramThumbnail ref={ref} {...props} />
        default: return `Unknown thumbnail type: ${type}`
    }
})