import Ajv from "ajv";
import { matchPath } from "react-router-dom";
import schema from "./schema";

const ajv = new Ajv();
for (const key in schema) ajv.addSchema(schema[key], key);
const pathToSchema = {
  "/visit-and-tickets": "visit-and-tickets.json",
  "/a-power-store": "a-power-store.json",
  "/a-power-store/:slug": "product.json",
  "/about-psa": "about-psa.json",
  "/psa-collections": "psa-collections.json",
  "/psa-collections/:slug": "artwork.json",
  "/whats-on": "whats-on.json",
  "/whats-on/exhibitions": "exhibitions.json",
  "/whats-on/exhibitions/:slug": "exhibition.json",
  "/whats-on/activities": "activities.json",
  "/whats-on/activities/:slug": "activity.json",
  "/whats-on/news": "news.json",
  "/whats-on/news/:slug": "news-item.json",
  "/whats-on/programs/:slug": "program.json",
  "/whats-on/programs/:slug/:page": "program-page.json",
  "/more/:slug": "page.json",
  "/search": "search-results.json",
  "/global": "global.json",
};

export const getSchema = path => {
  const pattern = Object.keys(pathToSchema).find(pattern =>
    matchPath(path, { path: pattern, exact: true })
  );
  return pathToSchema[pattern];
};

export default (path, data) => {
  path = path.split("?")[0];
  const pattern = Object.keys(pathToSchema).find(pattern =>
    matchPath(path, { path: pattern, exact: true })
  );
  if (!pattern) throw new Error(`No schema for path ${path}`);
  const schema = pathToSchema[pattern];
  if (!ajv.validate(schema, data)) {
    throw new Error(
      `API response for ${path} does not match schema ${schema}\n\n${JSON.stringify(
        ajv.errors,
        null,
        4
      )}`
    );
  }
};
